var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6"},[_c('h3',[_vm._v("Top users :")]),(!_vm.datKing.length)?_c('div',{staticClass:"pa-5"},_vm._l((2),function(g){return _c('div',{key:g,staticClass:"ma-2"},[_c('v-flex',{attrs:{"xs12":"","md10":"","sm10":"","justify-center":""}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"list-item-avatar-three-line"}},'v-skeleton-loader',_vm.attrs,false))],1)],1)}),0):_vm._l((_vm.datKing),function(item,items){return _c('v-card',{key:items,staticClass:"mb-2"},[_c('div',[_c('router-link',{attrs:{"to":{
          name: 'Profile',
          params: { id: item.user.id },
        }}},[_c('v-alert',{class:{
            maincolor2: items == 0,
            maincolor1: items == 1,
            'maincolor1 ': items == 2,
          },staticStyle:{"color":"black"},attrs:{"dense":"","elevation":"4","text":"","border":"left","icon":"mdi-medal-outline"}},[_c('span',{class:{
              'float-right': _vm.$vuetify.rtl,
              'float-left': !_vm.$vuetify.rtl,
            }},[_vm._v(" "+_vm._s(items + 1)+" - "),_c('v-icon',{},[_vm._v("mdi-account")]),_vm._v(_vm._s(item.user.username_u))],1),_c('strong',{class:{
              'mr-1': _vm.$vuetify.rtl,
              'ml-1': !_vm.$vuetify.rtl,
            },staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.allvictims)+" "+_vm._s(_vm.$t("dash.allvictims"))+" ")]),_c('span',{class:{
              'float-right': !_vm.$vuetify.rtl,
              'float-left': _vm.$vuetify.rtl,
            }})])],1)],1)])}),_c('router-link',{attrs:{"to":"rank"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"rounded":"","color":"success","outlined":""}},[_c('span',[_c('v-icon',{staticClass:"blue--text"},[_vm._v("mdi-podium-gold")]),_vm._v(" "+_vm._s(_vm.$t("gen.brank"))+" ")],1)])],1),_c('router-link',{attrs:{"to":"static"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"rounded":"","color":"danger","outlined":""}},[_c('span',[_c('v-icon',{staticClass:"blue--text"},[_vm._v("mdi-poll-box-outline")]),_vm._v(" "+_vm._s(_vm.$t("gen.blast"))+" ")],1)])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }