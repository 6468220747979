<template>
  <div class="col-md-6">
    <h3>Top users :</h3>
    <div v-if="!datKing.length" class="pa-5">
      <div class="ma-2" v-for="g in 2" :key="g">
        <v-flex xs12 md10 sm10 justify-center>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </v-flex>
      </div>
    </div>
    <v-card v-else v-for="(item, items) in datKing" :key="items" class="mb-2">
      <div>
        <router-link
          :to="{
            name: 'Profile',
            params: { id: item.user.id },
          }"
        >
          <v-alert
            dense
            elevation="4"
            text
            style="color: black"
            border="left"
            icon="mdi-medal-outline"
            :class="{
              maincolor2: items == 0,
              maincolor1: items == 1,
              'maincolor1 ': items == 2,
            }"
          >
            <span
              :class="{
                'float-right': $vuetify.rtl,
                'float-left': !$vuetify.rtl,
              }"
            >
              {{ items + 1 }} - <v-icon class="">mdi-account</v-icon
              >{{ item.user.username_u }}</span
            >
            <strong
              style="color: red"
              :class="{
                'mr-1': $vuetify.rtl,
                'ml-1': !$vuetify.rtl,
              }"
              >{{ item.allvictims }} {{ $t("dash.allvictims") }}
            </strong>
            <span
              :class="{
                'float-right': !$vuetify.rtl,
                'float-left': $vuetify.rtl,
              }"
            >
            </span>
          </v-alert>
        </router-link>
      </div>
   
    </v-card>
       <router-link to="rank">
        <v-btn class="ma-2" rounded color="success" outlined>
          <span>
            <v-icon class="blue--text">mdi-podium-gold</v-icon>
            {{ $t("gen.brank") }}
          </span>
        </v-btn>
      </router-link>
      <router-link to="static">
        <v-btn class="ma-2" rounded color="danger" outlined>
          <span>
            <v-icon class="blue--text">mdi-poll-box-outline</v-icon>
            {{ $t("gen.blast") }}
          </span>
        </v-btn>
      </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datKing: [],
    };
  },
  methods: {
    kkk: function (params) {
      this.$router.replace({
        name: "Profile",
        params: { id: params },
      });
    },
  },
  mounted() {
    this.$http
      .get("/u/king")
      .then((res) => {
        this.datKing = res.data;
      })
      .catch((err) => {
        this.$notify({
          group: "error",
          type: "error",
          title: "error",
          text: "error get king of the spam" + err.message,
        });
      });
  },
};
</script>